import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import { Reload } from 'mdi-material-ui';
import { FC, ReactNode } from 'react';

import CenterContainer from '../CenterContainer';
import HeadTitle from '../HeadTitle';

const displayName = 'Exception';

const useStyles = makeStyles(
  (theme) => ({
    container: {
      alignItems: 'center',
      display: 'flex !important',
      flex: 1,
      height: '100%',
      justifyContent: 'center',
      textAlign: 'center',
      width: '100%',
    },
    extra: {
      marginTop: theme.spacing(2),
    },
  }),
  { name: displayName },
);

type ExceptionProps = {
  description: ReactNode;
  extra?: ReactNode;
  title?: string;
};

const Exception: FC<ExceptionProps> = ({
  description,
  extra = (
    <Button
      href={window.location.href}
      startIcon={<Reload />}
      variant="contained"
    >
      Reload
    </Button>
  ),
  title = 'Unexpected Error',
}) => {
  const classes = useStyles();

  return (
    <>
      <HeadTitle>{title}</HeadTitle>
      <CenterContainer className={classes.container}>
        <Box>
          {title && (
            <Typography component="div" gutterBottom variant="h5">
              {title}
            </Typography>
          )}
          {description && (
            <Typography component="div" variant="body1">
              {description instanceof Error
                ? description.toString()
                : description}
            </Typography>
          )}
          {extra && <div className={classes.extra}>{extra}</div>}
        </Box>
      </CenterContainer>
    </>
  );
};

Exception.displayName = displayName;

export default Exception;
