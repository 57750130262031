import { lazy } from 'react';
import { Helmet } from 'react-helmet';
import { Route, Routes } from 'react-router-dom';

import logo from '../../logo.png';
import paths from '../../paths.json';
import ResetPassword from '../../routes/ResetPassword';
import { useOrganizationSettings } from '../OrganizationProvider';

const displayName = 'App';

const Home = lazy(() => import('../../routes/Home'));
const Login = lazy(() => import('../../routes/Login'));
const ChangePassword = lazy(() => import('../../routes/ChangePassword'));
const Signup = lazy(() => import('../../routes/Signup'));
const TermsAndConditions = lazy(() =>
  import('../../routes/TermsAndConditions'),
);
const PrivacyPolicy = lazy(() => import('../../routes/PrivacyPolicy'));

const App = () => {
  const { logoUrl } = useOrganizationSettings();

  return (
    <>
      <Helmet>
        <link href={logoUrl || logo} rel="shortcut icon" />
      </Helmet>
      <Routes>
        <Route element={<Login />} path={paths.login} />
        <Route element={<ChangePassword />} path={paths.changePassword} />
        <Route element={<ResetPassword />} path={paths.resetPassword} />
        <Route element={<Signup />} path={paths.signup} />
        <Route
          element={<TermsAndConditions />}
          path={paths.termsAndConditions}
        />
        <Route element={<PrivacyPolicy />} path={paths.privacyPolicy} />
        <Route element={<Home />} path="*" />
      </Routes>
    </>
  );
};

App.displayName = displayName;

export default App;
