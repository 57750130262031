import PropTypes from 'prop-types';
import { createContext, FC, useContext } from 'react';

import Exception from '../Exception';
import Loading from '../Loading';

import {
  PartnerConfigQuery,
  usePartnerConfigQuery,
} from './PartnerConfig.generated';

type Config = PartnerConfigQuery['partnerPublisherConfig'];

export const PartnerConfigContext = createContext<Config | undefined>(
  undefined,
);

export const usePartnerConfig = (): Config => {
  const config = useContext(PartnerConfigContext);

  if (!config) {
    throw new Error('No platform config');
  }

  return config;
};

const PartnerConfigProvider: FC = ({ children }) => {
  const { data, error, loading } = usePartnerConfigQuery();

  const config = data?.partnerPublisherConfig;

  if (error) {
    return (
      <Exception
        description="Thank you for being patient while we do some work on the site. Please check back soon."
        title="Maintenance"
      />
    );
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <PartnerConfigContext.Provider value={config}>
      {children}
    </PartnerConfigContext.Provider>
  );
};

PartnerConfigProvider.propTypes = {
  children: PropTypes.node,
};

PartnerConfigProvider.displayName = 'PartnerConfigProvider';

export default PartnerConfigProvider;
