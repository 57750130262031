import * as Types from '../../graphql.generated';

import { gql } from '@apollo/client';
export type AuthFragment = {
  __typename?: 'AuthResponse';
  authChallenge: Types.AuthChallenge;
  user: {
    __typename?: 'User';
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    permissions: Array<Types.Permission>;
    roles: Array<Types.Role>;
    status: Types.UserStatus;
  } | null;
  token: {
    __typename?: 'AuthToken';
    accessToken: string;
    expiresIn: number;
    refreshToken: string;
  } | null;
};

export const AuthFragmentDoc = gql`
  fragment Auth on AuthResponse {
    authChallenge
    user {
      id
      firstName
      lastName
      email
      permissions
      roles
      status
    }
    token {
      accessToken
      expiresIn
      refreshToken
    }
  }
`;
