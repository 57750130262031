import { FetchResult } from '@apollo/client';
import PropTypes from 'prop-types';
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import {
  AuthChangePasswordNewUserInput,
  AuthToken,
} from '../../graphql.generated';
import paths from '../../paths.json';
import { setAuthorization } from '../GraphQLProvider/authLink';
import Loading from '../Loading';

import {
  ChangePasswordMutation,
  useChangePasswordMutation,
} from './ChangePassword.generated';
import {
  ForgotPasswordMutation,
  useForgotPasswordMutation,
} from './ForgotPassword.generated';
import { LoginMutation, useLoginMutation } from './Login.generated';
import {
  RefreshTokenMutation,
  useRefreshTokenMutation,
} from './RefreshToken.generated';
import {
  ResetPasswordMutation,
  useResetPasswordMutation,
} from './ResetPassword.generated';

type Authentication = {
  accessToken?: string;
  authenticated: boolean;
  forgotPassword: (
    email: string,
  ) => Promise<FetchResult<ForgotPasswordMutation>>;
  resetPassword: (
    email: string,
    password: string,
    token: string,
  ) => Promise<FetchResult<ResetPasswordMutation>>;
  changePassword: (
    input: AuthChangePasswordNewUserInput,
  ) => Promise<FetchResult<ChangePasswordMutation>>;
  login: (
    username: string,
    password: string,
  ) => Promise<FetchResult<LoginMutation>>;
  logout: () => void;
  refreshToken?: string;
  renewToken: () => Promise<FetchResult<RefreshTokenMutation>>;
};

type AuthenticationState = Pick<AuthToken, 'accessToken' | 'refreshToken'> & {
  expiresAt: number;
};

const contextError = () => {
  throw new Error('No <AuthenticationProvider />');
};

const contextErrorPromise = () => Promise.reject(contextError());

const AuthenticationContext = createContext<Authentication>({
  authenticated: false,
  changePassword: contextErrorPromise,
  forgotPassword: contextErrorPromise,
  login: contextErrorPromise,
  logout: () => undefined,
  renewToken: contextErrorPromise,
  resetPassword: contextErrorPromise,
});

export const useAuthentication = (): Authentication =>
  useContext(AuthenticationContext);

const getExpiresAt = (expiresIn: number) =>
  new Date(Date.now() + expiresIn * 1e3).getTime();

const AuthenticationProvider: FC = ({ children }) => {
  const navigate = useNavigate();

  const [authentication, setAuthenticationState] = useState<
    AuthenticationState | undefined
  >(() => {
    const item = localStorage.getItem('authentication');

    if (!item) {
      return;
    }

    try {
      return JSON.parse(item) as AuthenticationState;
    } catch (e) {
      return undefined;
    }
  });

  const renewIn = useMemo(() => {
    return (
      authentication &&
      Math.max(0, authentication.expiresAt - Date.now() - 36e5)
    );
  }, [authentication]);

  const setAuthentication = useCallback(
    (result: Pick<AuthToken, 'accessToken' | 'expiresIn' | 'refreshToken'>) => {
      setAuthenticationState({
        accessToken: result.accessToken,
        expiresAt: getExpiresAt(result.expiresIn),
        refreshToken: result.refreshToken,
      });
    },
    [],
  );

  const [loginMutation] = useLoginMutation({
    onCompleted: (data) => {
      const token = data.login?.token;

      if (token) {
        setAuthentication(token);
      }
    },
  });
  const [changePasswordMutation] = useChangePasswordMutation({
    onCompleted: (data) => {
      const token = data.changePasswordNewUser?.token;

      if (token) {
        setAuthentication(token);
      }
    },
  });
  const [forgotPasswordMutation] = useForgotPasswordMutation();
  const [resetPasswordMutation] = useResetPasswordMutation({
    onCompleted: (data) => {
      const token = data.resetPassword?.token;
      if (token) {
        setAuthentication(token);
      }
    },
  });
  const [
    renewTokenMutation,
    { called: renewTokenCalled, loading: refreshing },
  ] = useRefreshTokenMutation({
    onCompleted: (data) => {
      const token = data.refreshToken?.token;
      if (token) {
        setAuthentication(token);
      }
    },
  });

  const timeoutRef = useRef<number | null>(null);

  const authenticated = useMemo(() => {
    if (!authentication) {
      return false;
    }

    return Date.now() <= new Date(authentication.expiresAt).getTime();
  }, [authentication]);

  const login = useCallback(
    (username: string, password: string) =>
      loginMutation({ variables: { input: { password, username } } }),
    [loginMutation],
  );

  const changePassword = useCallback(
    (values: AuthChangePasswordNewUserInput) =>
      changePasswordMutation({ variables: { input: values } }),
    [changePasswordMutation],
  );

  const forgotPassword = useCallback(
    (email: string) => forgotPasswordMutation({ variables: { email } }),
    [forgotPasswordMutation],
  );

  const resetPassword = useCallback(
    (email: string, password: string, token: string) =>
      resetPasswordMutation({ variables: { email, password, token } }),
    [resetPasswordMutation],
  );

  const logout = useCallback(() => {
    setAuthenticationState(undefined);
    navigate(paths.login);
  }, [navigate]);

  const renewToken = useCallback(() => {
    timeoutRef.current = null;

    if (authentication?.refreshToken) {
      return renewTokenMutation({
        variables: { input: { refreshToken: authentication?.refreshToken } },
      });
    }

    throw Error('No Refresh Token');
  }, [renewTokenMutation, authentication?.refreshToken]);

  useEffect(() => {
    if (!authentication) {
      localStorage.removeItem('authentication');

      return;
    }

    localStorage.setItem('authentication', JSON.stringify(authentication));

    timeoutRef.current = window.setTimeout(
      () => void renewToken()?.catch(console.warn),
      renewIn,
    );

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    };
  }, [authentication, renewToken, renewIn]);

  useEffect(() => {
    setAuthorization(authentication?.accessToken);
  }, [authentication?.accessToken]);

  // Wait until refresh token finishes
  if ((!renewTokenCalled && renewIn === 0) || refreshing) {
    return <Loading />;
  }

  return (
    <AuthenticationContext.Provider
      value={{
        accessToken: authentication?.accessToken,
        authenticated,
        changePassword,
        forgotPassword,
        login,
        logout,
        renewToken,
        resetPassword,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

AuthenticationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

AuthenticationProvider.displayName = 'AuthenticationProvider';

export default AuthenticationProvider;
