import { isNil } from 'lodash/fp';

export const composeValidators =
  (...validators: unknown[]) =>
  (...args: unknown[]) =>
    validators.reduce(
      (error, validator) =>
        error || (typeof validator === 'function' && validator(...args)),
      undefined,
    );

export const required = (value: unknown): string | undefined => {
  if ((Array.isArray(value) && !value.length) || isNil(value)) {
    return 'Required';
  }

  return undefined;
};

export const mustBeUrl = (value: unknown): string | undefined => {
  // eslint-disable-next-line no-useless-escape
  const regexp =
    /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@\-/]))?/;

  if (typeof value === 'string' && regexp.test(value)) {
    return undefined;
  }

  return 'Invalid URL';
};

export const maxLength =
  (length: number) =>
  (value: unknown): string | undefined =>
    typeof value === 'string' && value.length > length
      ? `Max. ${length} characters allowed`
      : undefined;
