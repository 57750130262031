import * as Types from '../../graphql.generated';

import { gql } from '@apollo/client';
import { AuthFragmentDoc } from './Auth.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LoginMutationVariables = Types.Exact<{
  input: Types.AuthLoginInput;
}>;

export type LoginMutation = {
  __typename?: 'Mutation';
  login: {
    __typename?: 'AuthResponse';
    authChallenge: Types.AuthChallenge;
    user: {
      __typename?: 'User';
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      permissions: Array<Types.Permission>;
      roles: Array<Types.Role>;
      status: Types.UserStatus;
    } | null;
    token: {
      __typename?: 'AuthToken';
      accessToken: string;
      expiresIn: number;
      refreshToken: string;
    } | null;
  };
};

export const LoginDocument = gql`
  mutation Login($input: AuthLoginInput!) {
    login(input: $input) {
      ...Auth
    }
  }
  ${AuthFragmentDoc}
`;
export type LoginMutationFn = Apollo.MutationFunction<
  LoginMutation,
  LoginMutationVariables
>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginMutation,
    LoginMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(
    LoginDocument,
    options,
  );
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>;
