import { FallbackRender } from '@sentry/react';

import Exception from '../Exception';

const ErrorBoundaryFallback: FallbackRender = (errorData) => {
  const { error } = errorData;

  const isChunkLoadError = error?.toString()?.includes('ChunkLoadError');

  if (isChunkLoadError) {
    return (
      <Exception
        description={
          <>
            A brand new version of partner platform is waiting for you.
            <br />
            Please reload the page to avail new features.
          </>
        }
        title="New Version Available"
      />
    );
  }

  return <Exception description={error} />;
};

export default ErrorBoundaryFallback;
