import { createContext, FC, useContext, useEffect } from 'react';

import Exception from '../Exception';
import Loading from '../Loading';
import { useThemeOptions } from '../ThemeProvider';

import {
  OrganizationQuery,
  useOrganizationQuery,
} from './Organization.generated';

type Organization = OrganizationQuery['organization'];

export const OrganizationContext = createContext<Organization | undefined>(
  undefined,
);

export const useOrganization = (): Organization => {
  const organization = useContext(OrganizationContext);

  if (!organization) {
    throw new Error('No organization');
  }

  return organization;
};

export const useOrganizationDomains = (): Organization['domains'] => {
  const { domains } = useOrganization();

  if (!domains) {
    throw new Error('No organization domains');
  }

  return domains;
};

export const useOrganizationSettings = (): Organization['whiteLabel'] => {
  const { whiteLabel: settings } = useOrganization();

  if (!settings) {
    throw new Error('No organization settings');
  }

  return settings;
};

const OrganizationProvider: FC = ({ children }) => {
  const { setThemeOptions } = useThemeOptions();
  const { data, error, loading } = useOrganizationQuery();

  const organization = data?.organization;

  useEffect(() => {
    const main = organization?.whiteLabel.primaryColor;

    if (!main) {
      return;
    }

    setThemeOptions((options) => ({
      ...options,
      palette: {
        ...options.palette,
        secondary: {
          ...options.palette?.secondary,
          main: main,
        },
      },
    }));
  }, [organization?.whiteLabel.primaryColor, setThemeOptions]);

  if (error) {
    return (
      <Exception
        description="Thank you for being patient while we do some work on the site. Please check back soon."
        title="Maintenance"
      />
    );
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <OrganizationContext.Provider value={organization}>
      {children}
    </OrganizationContext.Provider>
  );
};

OrganizationProvider.displayName = 'OrganizationProvider';

export default OrganizationProvider;
