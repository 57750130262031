import { Card, CardContent, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import type { FC } from 'react';

import {
  useOrganization,
  useOrganizationSettings,
} from '../../components/OrganizationProvider';
import logo from '../../logo.png';

const displayName = 'Welcome';

const useStyles = makeStyles(
  (theme) => ({
    card: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[2],
      maxWidth: theme.spacing(48),
      paddingBottom: theme.spacing(0),
    },

    header: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      marginBottom: `${theme.spacing(4)}!important`,
    },

    logo: {
      marginRight: theme.spacing(2),
    },

    main: {
      margin: 'auto',
      minHeight: 'auto',
      paddingBottom: theme.spacing(4),
      paddingTop: theme.spacing(4),
    },
  }),
  { name: displayName },
);

const Welcome: FC = ({ children }) => {
  const { name } = useOrganization();
  const { logoUrl } = useOrganizationSettings();

  const classes = useStyles();

  return (
    <main className={classes.main} data-test-route="login">
      <Typography className={classes.header} component="header" variant="h4">
        <img
          alt={`${name} logo`}
          className={classes.logo}
          height={40}
          src={logoUrl || logo}
          width={40}
        />
        {name}
      </Typography>
      <Card className={classes.card} elevation={0} square>
        <CardContent>{children}</CardContent>
      </Card>
    </main>
  );
};

Welcome.propTypes = {
  children: PropTypes.node.isRequired,
};

Welcome.displayName = displayName;

export default Welcome;
