import { Container, ContainerProps, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { ElementType, FC } from 'react';

const useStyles = makeStyles(
  (theme) => ({
    center: {
      padding: theme.spacing(2),
    },
  }),
  { name: 'CenterContainer' },
);

type CenterContainerProps = ContainerProps & {
  component?: ElementType;
};

const CenterContainer: FC<CenterContainerProps> = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <Container
      className={clsx(classes.center, className)}
      maxWidth="lg"
      {...rest}
    />
  );
};

export default CenterContainer;
